import React, { useState } from "react"
import * as R from "ramda"
import { useIntl } from "gatsby-plugin-intl"
import { graphql, Link } from "gatsby"
import { IArtist } from "../types/models"
import { GraphQLItemResponse, GraphQLListResponse } from "../types"
import Seo from "../components/seo"
import "./artists.scss"

export default function Artists({ data }: any) {
  const intl = useIntl()

  const listData = R.pipe<GraphQLListResponse<IArtist>, IArtist[], IArtist[]>(
    R.pathOr([], ["allStrapiArtist", "nodes"]),
    R.filter(i => R.path(["locale"])(i) === intl.locale),
    R.sortBy(a => {
      if (a?.slug) {
        return a?.slug?.split("-")[1].toUpperCase()
      } else if (a?.name) {
        return a?.name?.toUpperCase()
      } else {
        return "z"
      }
    })
  )(data)

  return (
    <>
      <Seo title="Artists" />
      <ul className="artists-list w-full px-6 pt-6 md:pt-0">
        {listData.map((item, idx: number) => (
          <li className="appearance-none w-full flex items-center justify-between">
            <Link
              to={`/${intl.locale}/artists/${item.slug}`}
              className={`name flex-1 ${idx % 2 ? "ABC-Mono-Italic" : "PPG"}`}
            >
              <div>{item.name}</div>
            </Link>

            <div className="ABC-Mono">
              {item?.songs?.length?.toString().padStart(3, "0")}
            </div>
            <div className="image-placeholder">
              <Link to={`/${intl.locale}/artists/${item.slug}`}>
                <img src={item?.avatar?.url} alt={item.slug} />
              </Link>
            </div>
          </li>
        ))}
      </ul>
    </>
  )
}

export const query = graphql`
  {
    allStrapiArtist(sort: { order: ASC, fields: name }) {
      nodes {
        name
        id
        locale
        slug
        avatar {
          url
        }
        songs {
          id
        }
      }
    }
  }
`
